/* eslint-disable @typescript-eslint/no-explicit-any */
import { cn } from '@bem-react/classname'
import { yupResolver } from '@hookform/resolvers/yup'
import queryString from 'query-string'
import { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import InputMask from 'react-input-mask'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  IQueryOrder,
  IRequisiteExchange,
  IResponseInfoExchanger,
} from '@/core/services/Exchange/ExchangeModel'
import {
  createExchangeThunk,
  exchangeThunk,
} from '@/core/services/Exchange/store/ExchangeSlice'
import { useAppDispatch } from '@/core/store/hooks'

import { Memo } from '@/hoc/Memo'

import useBackendError from '@/hooks/useBackendError'

import { createValidationSchema } from '@/utils/createValidationSchema'
import { removeEmpty } from '@/utils/removeEmpty'

import { AppContent, AppProfile, AppSidebar } from '@/components/AppProfile'
import { Back } from '@/components/Back'
import { Button } from '@/components/Button'
import { Loading } from '@/components/Loading'
import { StepInfo } from '@/components/StepInfo'
import { Title } from '@/components/Title'

import './styles/Order.scss'

const cnOrder = cn('Order')

export const Order: FC = Memo(() => {
  const { t } = useTranslation('translation')
  const dispatch = useAppDispatch()
  const { search } = useLocation()
  const [setBackendError] = useBackendError()
  const navigate = useNavigate()

  const [currency, setCurrency] = useState<{ from: string; to: string } | null>(
    null,
  )
  const [fields, setFields] = useState<any[]>([])
  // const [card, setCard] = useState<string>('')
  const [query, setQuery] = useState<IQueryOrder | null>(null)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isDisabled, setDisabled] = useState<boolean>(true)
  const [exchanger, setExchanger] = useState<IResponseInfoExchanger | null>(
    null,
  )
  const [requisites, setRequisites] = useState<IRequisiteExchange[]>([])

  useEffect(() => {
    if (requisites.length) {
      const arr = requisites.map(el => {
        const validations = ['string', 'trim']
        let params = {}

        if (el.required) {
          validations.push('required')
          params = {
            ...params,
            required: t('The field is required'),
          }
        } else {
          validations.push('nullable')
        }

        return {
          ...el,
          validations,
          params,
        }
      })

      setFields(arr)
    } else {
      setFields([])
    }
  }, [requisites])

  useEffect(() => {
    if (search) {
      exchange(search)
      setLoading(true)
    }
  }, [search])

  // useEffect(() => {
  //   if (card && card.replaceAll(' ', '').length === 16) {
  //     setDisabled(false)
  //   }
  // }, [card])

  useEffect(() => {
    if (query) {
      if (query.direction) {
        const arr = String(query.direction).split('/')

        if (arr.length === 2) {
          setCurrency({
            from: arr[0],
            to: arr[1],
          })
        }
      }
    }
  }, [query])

  const exchange = async (s: string) => {
    const data = queryString.parse(s) as unknown as IQueryOrder

    if (!data) return

    setQuery(data)

    try {
      const res = await dispatch(
        exchangeThunk({
          sum: +data.from,
          direction: data.direction,
          payment_id: +data.payment_id,
        }),
      ).unwrap()
      setLoading(false)

      if (res && res.info) {
        setExchanger(res.info.exchanger)
      }

      if (res && res.info.requisites) {
        setRequisites(res.info.requisites)
      }
    } catch (errors) {
      setBackendError(errors)
      setLoading(false)
    }
  }

  const schema = createValidationSchema(fields)

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<any>({
    // defaultValues: new ExchangeModel(),
    resolver: yupResolver(schema),
    mode: 'onChange',
  })

  useEffect(() => {
    if (errors) {
      setDisabled(false)
    }
  }, [errors])

  const createExchange = async (data: any) => {
    const form = removeEmpty({
      sum: query?.from,
      address: query?.address,
      direction: query?.direction,
      payment_id: query?.payment_id,
      ...data,
    })

    try {
      const { transaction_id } = await dispatch(
        createExchangeThunk(form),
      ).unwrap()

      if (transaction_id) {
        navigate(`/profile/transactions/${transaction_id}`)
      }
    } catch (errors) {
      setBackendError(errors)
      setLoading(false)
    }
  }

  const listInfo = [
    {
      title: t('New Order'),
      text: t(
        'You have created a new order to buy cryptocurrency. Please read the information provided carefully. By clicking the "Pay" button, you confirm the correctness of the data in the purchase order. ',
      ),
    },
    {
      title: t('Payment'),
      text: t(
        'Enter your card details to pay for the cryptocurrency purchase order. All data is protected by the payment provider. After a successful payment, the cryptocurrency will be sent to your internal address in the Exflow wallet.',
      ),
    },
    {
      title: t('Cards details'),
      text: t(
        'In the details of the transaction, you can monitor the status of your application. As soon as the application takes the status "Completed", the cryptocurrency will be sent to your address in the Exflow wallet. Return to the main page to see the balance of the wallet. ',
      ),
    },
  ]

  const listInfoSepa = [
    {
      title: t('Order'),
      text: t('Check that the data is correct'),
    },
    {
      title: t('SEPA TRANSFER'),
      text: t(
        'Check that the data is correct. The request has been created, we are waiting for your payment',
      ),
    },
  ]

  return (
    <>
      <Helmet>
        <title>{t('New order')}</title>
        <meta name='description' content={''} />
      </Helmet>
      <AppProfile>
        <AppContent>
          <div className={cnOrder('top')}>
            <Title>{t('New order')}</Title>
            <Back text={t('Back')} />
          </div>

          <form onSubmit={handleSubmit(createExchange)}>
            {isLoading ? (
              <Loading type='large' className={cnOrder('loading')} />
            ) : (
              <>
                {currency && exchanger && (
                  <div className={cnOrder('wrap')}>
                    <div className={cnOrder('block')}>
                      <div className={cnOrder('title')}>{t('You pay')}</div>
                      <div className={cnOrder('value')}>
                        {exchanger.sum_pay} {currency.from}
                      </div>
                    </div>
                    <div className={cnOrder('block')}>
                      <div className={cnOrder('title')}>{t('You get')}</div>
                      <div className={cnOrder('value')}>
                        {exchanger.sum_buy} {currency.to}
                      </div>
                    </div>

                    {query && query.address && (
                      <div className={cnOrder('block')}>
                        <div className={cnOrder('title')}>
                          {t('To the address')}
                        </div>
                        <div className={cnOrder('value')}>{query.address}</div>
                      </div>
                    )}

                    {requisites.length > 0 &&
                      query &&
                      query.payment_method !== 'card' && (
                        <>
                          {requisites.map(el => (
                            <div key={el.name} className={cnOrder('block')}>
                              <div className={cnOrder('title')}>
                                {t(el.title)}
                              </div>
                              <div className={cnOrder('field')}>
                                <input
                                  type='text'
                                  placeholder={el.title}
                                  className={cnOrder('input')}
                                  {...register(el.name)}
                                />

                                {errors &&
                                  errors[el.name] &&
                                  errors[el.name]?.message && (
                                    <div className={cnOrder('error')}>
                                      {String(errors[el.name]?.message)}
                                    </div>
                                  )}
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                  </div>
                )}
              </>
            )}

            {query &&
              query.payment_method === 'card' &&
              query.action === 'sell' && (
                <div className={cnOrder('bottom')}>
                  <div className={cnOrder('left')}>
                    <div className={cnOrder('img')}>
                      <img src='/img/payment.png' alt='' />
                    </div>
                    <InputMask
                      mask='9999 9999 9999 9999'
                      placeholder='XXXX XXXX XXXX XXXX'
                      className={cnOrder('input')}
                      // onChange={e => setCard(e.target.value)}
                      // value={card}
                      maskPlaceholder={null}
                      {...register('card_number')}
                    />
                  </div>
                  <div className={cnOrder('right')}>
                    {t(
                      'Order security is ensured by using the SSL protocol to transfer confidential information from the client to the server for further processing. The subsequent processing of the clients confidential data received (card details, registration data, etc.) is carried out in the processing center. This way, no one can get your credit card bank details.',
                    )}
                  </div>
                </div>
              )}

            <div className={cnOrder('action')}>
              <Button
                type='submit'
                className={cnOrder('submit')}
                size='xl'
                isLoading={isSubmitting}
                disabled={isDisabled}
              >
                {t('Confirm')}
              </Button>
            </div>
          </form>
        </AppContent>

        <AppSidebar padding>
          {query && query.payment_method === 'card' ? (
            <StepInfo
              list={listInfo}
              currentStepInfo={1}
              title={t('Transaction Steps')}
            />
          ) : (
            <StepInfo
              list={listInfoSepa}
              currentStepInfo={1}
              title={t('Transaction Steps')}
            />
          )}
        </AppSidebar>
      </AppProfile>
    </>
  )
})
