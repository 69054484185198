import { cn } from '@bem-react/classname'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Memo } from '@/hoc/Memo'

import useWindowSize from '@/hooks/useWindowSize'

import './Footer.scss'

const cnFooter = cn('Footer')

export const Footer: FC = Memo(() => {
  const { t } = useTranslation('translation')
  const { lessDesktop } = useWindowSize()

  return (
    <footer className={cnFooter()}>
      <div className={cnFooter('line')}>
        {!lessDesktop && (
          <div className={cnFooter('left')}>
            <div className={cnFooter('text')}>
              {t('All rights reserved by Futuritix Ltd')}
            </div>
          </div>
        )}
        <div className={cnFooter('center')}>
          <div className={cnFooter('item')}>
            <div className={cnFooter('header')}>{t('Company')}:</div>

            <a
              className={cnFooter('link')}
              href='https://futuritix.io/about'
              target='_blank'
            >
              {t('About us')}
            </a>

            {/* <a className={cnFooter('link')}> {t('Careers')}</a> */}
          </div>
          <div className={cnFooter('item')}>
            <div className={cnFooter('header')}>{t('Legal')}:</div>

            <a
              className={cnFooter('link')}
              href='https://futuritix.io/terms-and-conditions'
              target='_blank'
            >
              {t('Terms and conditions')}
            </a>

            <a
              className={cnFooter('link')}
              href='https://futuritix.io/privacy-policy'
              target='_blank'
            >
              {t('Privacy policy')}
            </a>

            <a
              className={cnFooter('link')}
              href='hhttps://futuritix.io/cookies-policy'
              target='_blank'
            >
              {t('Cookies Policy')}
            </a>
          </div>

          <div className={cnFooter('item')}>
            <div className={cnFooter('header')}>{t('Information')}:</div>

            <a
              className={cnFooter('link')}
              href='https://futuritix.io/faq'
              target='_blank'
            >
              {t('Questions')}
            </a>
            <a
              className={cnFooter('link')}
              href='https://futuritix.io/services'
              target='_blank'
            >
              {t('Services')}
            </a>
            <a
              className={cnFooter('link')}
              href='https://futuritix.io/crypto'
              target='_blank'
            >
              {t('Crypto')}
            </a>

            <a
              className={cnFooter('link')}
              href='https://futuritix.io/contact'
              target='_blank'
            >
              {t('Contacts')}
            </a>
          </div>
        </div>

        <div className={cnFooter('right')}>
          {lessDesktop && (
            <div className={cnFooter('text', { bottom: true })}>
              {t('All rights reserved by Futuritix Ltd')}
            </div>
          )}
          <div className={cnFooter('text')}>
            {t(
              'Futuritix.io is operated by Futuritix Ltd (Reg. No. 207460423). Registered address Bulgaria, Sofia, 1000, Triaditsa district, 30 Petar Parchevich Str.',
            )}
          </div>
        </div>
      </div>
    </footer>
  )
})
